
import {defineComponent, onMounted, ref} from "vue";
import {createEmbeddingContext} from "amazon-quicksight-embedding-sdk";
import QuicksightService from "@/core/services/QuicksightService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import {generateString} from "@/core/helpers/functions";

export default defineComponent({
  name: "QuicksightEmbed",
  components: {EntityLoading},
  props: {
    elementId: {type: String, required: true},
    dashboardId: {type: String, required: true},
    title: {type: String, required: true},
    height: {type: Number, default: () => 500},
    params: {
      required: false, default: () => {
      }
    }
  },
  setup(props) {
    const loading = ref(true);

    const load = async () => {
      const embeddingContext = await createEmbeddingContext({
        onChange: (changeEvent, metadata) => {
          console.log('Context received a change', changeEvent, metadata);
        },
      });
      loading.value = true;
      QuicksightService.dashboard(props.dashboardId).then(res => {
        const frameOptions: any = {
          url: res.url + generateString(props.params),// replace this value with the url generated via embedding API
          container: "#" + props.elementId,
          height: props.height + "px",
        };
        const contentOptions = {
          locale: "en-US",
          toolbarOptions: {
            export: false,
            undoRedo: false,
            reset: false
          },
          attributionOptions: {
            overlayContent: false,
          },
        };
        embeddingContext.embedDashboard(frameOptions, contentOptions);
      }).finally(() => {
        loading.value = false;
      })
    }
    onMounted(async () => {
      await load();
    })
    return {
      loading
    }
  }
})
