import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  key: 0,
  class: "card-body"
}
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_EntityLoading)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      class: "card-body p-0",
      id: _ctx.elementId
    }, null, 8, _hoisted_5), [
      [_vShow, !_ctx.loading]
    ])
  ], 512)), [
    [_vShow, !_ctx.loading]
  ])
}