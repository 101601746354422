
import {defineComponent} from "vue";
import VisualEmbed from "@/views/quicksight/VisualEmbed.vue";
import QuicksightEmbed from "@/views/quicksight/QuicksightEmbed.vue";

export default defineComponent({
  name: "Visual",
  components: {QuicksightEmbed, VisualEmbed},
  setup() {
  }
})
